define("discourse/plugins/discourse-zoom/discourse/components/webinar-register", ["exports", "@ember/component", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/utilities", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _ajax, _utilities, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STARTED = "started",
    ENDED = "ended";
  var _default = _exports.default = _component.default.extend(dt7948.p({
    loading: false,
    registered: (0, _computed.or)("isHost", "isPanelist", "isAttendee"),
    webinarStarted(webinar) {
      const beforeStart = this.siteSettings.zoom_join_x_mins_before_start;
      if (webinar.status === STARTED) {
        if (!beforeStart) {
          return true;
        }
        const newStartTime = moment(webinar.starts_at).subtract(beforeStart, "minutes");
        if (newStartTime.isBefore(moment())) {
          return true;
        }
      }
      return false;
    },
    webinarEnded(webinar) {
      if (webinar.status === ENDED || moment(webinar.ends_at).isBefore(moment())) {
        return true;
      }
      return false;
    },
    isAppWebview() {
      return this.capabilities.isAppWebview;
    },
    isAttendee(user, attendees) {
      if (attendees) {
        for (let attendee of attendees) {
          if (attendee.id === user.id) {
            return true;
          }
        }
      }
      return false;
    },
    isHost(user, host) {
      if (host) {
        return user.id === host.id;
      }
      return false;
    },
    isPanelist(user, panelists) {
      for (let panelist of panelists) {
        if (panelist.id === user.id) {
          return true;
        }
      }
      return false;
    },
    canUnregister(starts_at, isAttendee, registered) {
      if (moment(starts_at).isBefore(moment())) {
        return false;
      }
      return isAttendee && registered;
    },
    userCanRegister(isAttendee, registered) {
      return !isAttendee && !registered;
    },
    toggleRegistration(registering) {
      const method = registering ? "PUT" : "DELETE";
      this.set("loading", true);
      return (0, _ajax.ajax)(`/zoom/webinars/${this.webinar.id}/attendees/${this.currentUser.username}.json`, {
        type: method
      }).then(() => {
        this.store.find("webinar", this.webinar.id).then(webinar => {
          this.set("webinar", webinar);
        });
        this.set("loading", false);
      }).finally(() => this.set("loading", false));
    },
    downloadName(title) {
      return title + ".ics";
    },
    addToGoogleCalendarUrl(webinar) {
      return `http://www.google.com/calendar/event?action=TEMPLATE&text=${encodeURIComponent(webinar.title)}&dates=${this.formatDateForGoogleApi(webinar.starts_at)}/${this.formatDateForGoogleApi(webinar.ends_at)}&details=${encodeURIComponent(this.formatDescriptionForGoogleApi(webinar.join_url))}&location=${encodeURIComponent(webinar.join_url)}`;
    },
    downloadIcsUrl(webinar) {
      const now = this.formatDateForIcs(new Date());
      return `data:text/calendar;charset=utf-8,` + encodeURIComponent(`BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//hacksw/handcal//NONSGML v1.0//EN\nBEGIN:VEVENT\nUID:${now}-${webinar.title}\nDTSTAMP:${now}\nDTSTART:${this.formatDateForIcs(webinar.starts_at)}\nDTEND:${this.formatDateForIcs(webinar.ends_at)}\nSUMMARY:${webinar.title}\nEND:VEVENT\nEND:VCALENDAR`);
    },
    joinViaZoom(joinUrl) {
      if (joinUrl && this.siteSettings.zoom_use_join_url) {
        return joinUrl;
      } else {
        return false;
      }
    },
    formatDateForGoogleApi(date) {
      return new Date(date).toISOString().replace(/-|:|\.\d\d\d/g, "");
    },
    formatDescriptionForGoogleApi(joinUrl) {
      return `Join from a PC, Mac, iPad, iPhone or Android device:
    Please click this URL to join. <a href="${joinUrl}">${joinUrl}</a>`;
    },
    formatDateForIcs(date) {
      return moment(date).utc().format("YYYYMMDDTHHmmss") + "Z";
    },
    actions: {
      register() {
        this.toggleRegistration(true);
      },
      unregister() {
        this.toggleRegistration(false);
      },
      addEventAppWebview() {
        const event = {
          title: this.webinar.title,
          starts_at: this.webinar.starts_at,
          ends_at: this.webinar.ends_at
        };
        (0, _utilities.postRNWebviewMessage)("eventRegistration", JSON.stringify(event));
      },
      joinSDK() {
        const url = this.siteSettings.zoom_enable_sdk_fallback ? `/zoom/webinars/${this.webinar.id}/sdk?fallback=1` : `/zoom/webinars/${this.webinar.id}/sdk`;
        if (this.registered) {
          window.location.href = url;
        } else {
          this.toggleRegistration(true).then(() => {
            window.location.href = url;
          });
        }
      }
    }
  }, [["method", "webinarStarted", [(0, _decorators.default)("webinar.{status,ends_at}")]], ["method", "webinarEnded", [(0, _decorators.default)("webinar.{status,ends_at}")]], ["method", "isAppWebview", [_decorators.default]], ["method", "isAttendee", [(0, _decorators.default)("currentUser", "webinar.attendees")]], ["method", "isHost", [(0, _decorators.default)("currentUser", "webinar.host")]], ["method", "isPanelist", [(0, _decorators.default)("currentUser", "webinar.panelists")]], ["method", "canUnregister", [(0, _decorators.default)("webinar.starts_at", "isAttendee", "registered")]], ["method", "userCanRegister", [(0, _decorators.default)("isAttendee", "registered")]], ["method", "downloadName", [(0, _decorators.default)("webinar.title")]], ["method", "addToGoogleCalendarUrl", [(0, _decorators.default)("webinar.{starts_at,ends_at}")]], ["method", "downloadIcsUrl", [(0, _decorators.default)("webinar.{starts_at,ends_at}")]], ["method", "joinViaZoom", [(0, _decorators.default)("webinar.join_url")]]]));
});